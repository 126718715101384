import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LoaderIcon from './LoaderIcon';
import AddressListWidget from '../components/AddressListWidget';
import SubscriptionPicker from '../components/SubscriptionPicker';
import MUILink from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import BlockIcon from '@mui/icons-material/Block';
import Button from '@mui/material/Button';
import YesNoDialog from './YesNoDialog';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';

const AccountDetailWidgetBody = (props) => {
   const [deleteProfileDialogOpen, setDeleteProfileDialogOpen] = useState(false);

   if (props.loading)
      return (<LoaderIcon />);

   return (
      <Fragment>
         <CardContent>
            <Grid container spacing={2}>
               <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                           LifeTomb Account
                        </Typography>
                     </Grid>

                     <Grid item xs={12}>
                        <b>Subscription Details</b>
                        <p>LifeTomb offers several subscription tiers, taylored to fit your unique scenario. Paid subscriptions renew monthly. <MUILink onClick={props.onSubscriptionInfoClick} sx={{cursor: 'pointer'}}>Find out what additional features each tier offers</MUILink>.</p>

                        {props.profile && props.profile.subscriptionTier && props.profile.subscriptionTier.id != 'free' && props.profile.subscriptionTier.subscriptionProviderId && props.profile.subscriptionTier.subscriptionProviderId != 'stripe' ? <Alert severity="info">Your subscription is managed via mobile ({props.profile.subscriptionTier.subscriptionProviderId == 'apple' ? <AppleIcon /> : <AndroidIcon /> }). Please use your mobile device to make changes.</Alert> : null}

                        {props.profile && props.profile.subscriptionTier && props.profile.subscriptionTier.isLocked ? <Alert severity="info">Your subscription is currently locked. Please contact support to make changes.</Alert> : null}
                     </Grid>

                     {props.profile && props.profile.subscriptionTier ? <SubscriptionPicker
                        subscriptionStatus={props.profile.subscriptionTier}
                        subscriptionTiers={props.subscriptionTiers}
                        isStripeCustomer={props.profile.isStripeCustomer || false}
                        onNewSubscription={props.onNewSubscription}
                        onChangeSubscription={props.onChangeSubscription}
                        onCancelSubscription={props.onCancelSubscription}
                        onContinueWithoutChange={() => console.log('nothing to change')}
                        onBillingPortalClick={props.onBillingPortalClick}
                     /> : null}
                  </Grid>
               </Grid>

               <Grid item xs={12} md={6}>
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                           Defaults &amp; Account Data
                        </Typography>
                     </Grid>

                     {props.featureFlags && props.featureFlags && props.featureFlags.find(el => el.id === 'export-lifetomb-as-pdf') ? <Grid item xs={12}>
                        <b>Get Your LifeTomb & Data</b>
                        <p>Generate a PDF containing your LifeTomb data.</p>
                        <Button variant="contained" startIcon={<CloudDownloadIcon />} onClick={props.onGeneratePDFclick}>Generate PDF</Button>
                     </Grid> : null}

                     <Grid item xs={12}>
                        <b>Delete Your Profile</b>
                        <p>The following option allows you to delete your LifeTomb profile. Careful, this cannot be undone!</p>
                        <Button color="error" variant="contained" startIcon={<BlockIcon />} onClick={() => setDeleteProfileDialogOpen(true)}>Delete Profile</Button>
                     </Grid>

                     <Grid item xs={12}>
                        <b>Saved Addresses</b>
                        <p>The following are your stored addresses. You can store up to three to re-use them when you're managing your records.</p>
                     </Grid>

                     <Grid item xs={12}>
                        <AddressListWidget
                           loading={props.savedAddresses.loading}
                           savedAddresses={props.savedAddresses.data}
                        />
                     </Grid>

                     {props.featureFlags && props.featureFlags.length ? <Fragment>
                        <Grid item xs={12}>
                           <Typography variant="h6" gutterBottom component="div" sx={{ borderBottom: '3px solid #00a5de' }}>
                              Enabled Feature Flags
                           </Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <ul>
                              {props.featureFlags.map(flag => <li key={flag.id}>{flag.name}</li> )}
                           </ul>
                        </Grid>
                     </Fragment> : null}
                  </Grid>
               </Grid>
            </Grid>
         </CardContent>
         <YesNoDialog
            open={deleteProfileDialogOpen}
            title="Delete LifeTomb Profile?"
            description="Are you sure you want to delete your LifeTomb Profile? Deleting Your LifeTomb Profile:
            Will immediately disable your profile and prevent you from logging in through any of the LifeTomb applications (web, mobile) developed by LifeTomb LLC.
            Will immediately remove your mausoleum from the mausolea search.
            Will keep a family profile active, if another user is still active on the profile until they also choose to delete their account.
            Will delete your data after no more than 30 days from the time of this deletion request. This includes profile, journey, diary, records, final wishes and your mausoleum.
            Will cancel your subscription after no more than 30 days from the time of this deletion request. You may cancel your paid subscription prior to this deletion request if you do not want to wait 30 days for its cancellation."
            dismissButtonLabel="Cancel"
            affirmButtonLabel="Delete My Profile"
            handleDismiss={() => setDeleteProfileDialogOpen(false)}
            handleAffirm={props.onDeleteProfile}
         />
      </Fragment>
   )
};

AccountDetailWidgetBody.propTypes = {
   loading: PropTypes.bool.isRequired,
   profile: PropTypes.object.isRequired,
   savedAddresses: PropTypes.object.isRequired,
   subscriptionTiers: PropTypes.array.isRequired,
   onNewSubscription: PropTypes.func.isRequired,
   onChangeSubscription: PropTypes.func.isRequired,
   onCancelSubscription: PropTypes.func.isRequired,
   onBillingPortalClick: PropTypes.func.isRequired,
   onSubscriptionInfoClick: PropTypes.func.isRequired,
   onGeneratePDFclick: PropTypes.func.isRequired,
   onDeleteProfile: PropTypes.func.isRequired,
   featureFlags: PropTypes.array,
};

export default AccountDetailWidgetBody;
