import React, { useState, useRef, useEffect, Fragment } from 'react';
import systemLookupService from '../data/SystemLookupService';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import LoaderIcon from '../components/LoaderIcon';
import ProfileForm from '../components/ProfileForm';
import FormControl from '@mui/material/FormControl';
import profileService from '../data/ProfileService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MergeIcon from '@mui/icons-material/Merge';
import PubSub from 'pubsub-js';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Chip from '@mui/material/Chip';
import DialogComponent from '../components/DialogComponent';
import TierComparisonTable from '../components/TierComparisonTable';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import MUILink from '@mui/material/Link';
import { Skeleton } from '@mui/material';
import xss from 'xss';

const WizardPages = Object.freeze([
   {'value': 'profile-type', 'label': 'Profile Type'},
   {'value': 'personal-info', 'label': 'Personal Info'},
   {'value': 'billing-info', 'label': 'Billing Info'},
]);

const GetStartedPage = () => {
   const profileFormRef = useRef();

   const [loading, setLoading] = useState(false);
   const [termsAndConditions, setTermsAndConditions] = useState({ data: {}, loading: false, accepted: false });
   const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false);
   const [maritalStatuses, setMaritalStatuses] = useState({ data: [], loading: false });
   const [addressStatesProvinces, setAddressStatesProvinces] = useState({ data: [], loading: false });
   const [profilesImInvitedTo, setProfilesImInvitedTo] = useState({ data: [], loading: false });
   const [wizardPage, setWizardPage] = useState(WizardPages.findIndex(el => (el.value === 'profile-type')));
   const [inviteAction, setInviteAction] = useState('create-free-profile');
   const [inviteToAccept, setInviteToAccept] = useState(null);
   const [tierComparisonDialogOpen, setTierComparisonDialogOpen] = useState(false);
   const [subscriptionTierToHighlight, setSubscriptionTierToHighlight] = useState('free');
   const [externalIdpProfilePrefill, setExternalIdpProfilePrefill] = useState({ data: {}, loading: false });

   useEffect(() => {
      getTermsAndConditions();
      getMaritalStatuses();
      getAddressStatesProvinces();
      getProfilesImInvitedTo();
      getExternalIdpProfilePrefill();
   }, []);

   const history = useHistory();

   const getTermsAndConditions = async () => {
      setTermsAndConditions({ data: {}, loading: true, accepted: false });
      const termsAndConditions = await systemLookupService.getCurrentTermsAndConditions();
      setTermsAndConditions({ data: termsAndConditions, loading: false, accepted: false });
   };

   const handleTermsAndConditionsCheck = (event) => {
      setTermsAndConditions({ ...termsAndConditions, accepted: event.target.checked });
   };

   const getMaritalStatuses = async () => {
      setMaritalStatuses({ data: [], loading: true });
      const maritalStatuses = await systemLookupService.getMaritalStatuses();
      setMaritalStatuses({ data: maritalStatuses, loading: false });
   };

   const getAddressStatesProvinces = async () => {
      setAddressStatesProvinces({ data: [], loading: true });
      const addressStatesProvinces = await systemLookupService.getAddressStatesProvinces();
      setAddressStatesProvinces({ data: addressStatesProvinces, loading: false });
   };

   const getProfilesImInvitedTo = async () => {
      setProfilesImInvitedTo({ data: [], loading: true });
      const profilesImInvitedTo = await profileService.getProfilesImInvitedTo();
      setProfilesImInvitedTo({ data: profilesImInvitedTo, loading: false });

      if (profilesImInvitedTo.length > 0)
         handleProfileTypeRadioGroupChange(`accept-family-profile-invite-${profilesImInvitedTo[0].id}`)
   };

   const getExternalIdpProfilePrefill = async () => {
      setExternalIdpProfilePrefill({ data: {}, loading: true });
      const externalIdpProfilePrefill = await profileService.getExternalIdpProfilePrefill();

      setExternalIdpProfilePrefill({ data: externalIdpProfilePrefill, loading: false });
   };

   const navigateToWizardPage = async (slug) => {
      await setWizardPage(WizardPages.findIndex(el => (el.value === slug)));

      switch(slug) {
         case 'personal-info':
            if (profileFormRef.current) {
               profileFormRef.current.setState({
                  firstName: externalIdpProfilePrefill.data.firstName,
                  lastName: externalIdpProfilePrefill.data.lastName,
                  avatarBase64: externalIdpProfilePrefill.data && externalIdpProfilePrefill.data.avatarURL ? await getBase64FromUrl(externalIdpProfilePrefill.data.avatarURL) : null,
               });
            }
            break;
      }
   };

   // todo: move this into util
   const getBase64FromUrl = async (url) => {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
         const reader = new FileReader();
         reader.readAsDataURL(blob);
         reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
         }
      });
   };

   const personInfoFormValid = () => {
      let formValid = false;
      if (profileFormRef.current)
         formValid = profileFormRef.current.validate();

      return formValid;
   };

   const createProfile = async (newProfile) => {
      setLoading(true);
      await profileService.postProfile(newProfile);

      // todo: could actually get profile returned from call above and send it along with this event... may obviate the need for the basic profile API & call:
      PubSub.publish('profile.updated', null);
      PubSub.publish('profile.reload-trustors', null);

      // navigate to billing or optional wizard
      switch(inviteAction) {
         case 'create-premium-profile':
            history.push('/start-subscription?subscription-tier=premium');
            break;
         case 'create-family-profile':
            history.push('/start-subscription?subscription-tier=family');
            break;
         default:
            // history.push('/get-started-optional?page=1');
            PubSub.publish('profile.reload-notifications', null);
            history.push('/');
            break;
      }
   };

   const handlePersonInfoContinue = async () => {
      if(personInfoFormValid() && profileFormRef.current) {
         PubSub.publish('profile.reload-notifications', null);
         createProfile(createProfilePayload(profileFormRef.current.getState()));
      }
   };

   const createProfilePayload = (person) => {
      return {
         ...person,
         termsAndConditionsId: termsAndConditions.data.id,
         acceptInviteId: inviteToAccept
      };
   };

   const renderNextButtonName = () => {
      switch (inviteAction) {
         case 'create-free-profile':
            return 'Create Profile';
         case 'create-premium-profile':
         case 'create-family-profile':
            return 'Continue';
         default:
            return 'Join Profile';
      }
   };

   const renderNextButtonIcon = () => {
      switch (inviteAction) {
         case 'create-free-profile':
            return (<CheckIcon />);
         case 'create-premium-profile':
         case 'create-family-profile':
            return (<ArrowForwardIcon />);
         default:
            return (<MergeIcon />);
      }
   };

   const handleProfileTypeRadioGroupChange = (value) => {
      setInviteAction(value);

      if(value.startsWith('accept-family-profile-invite'))
         setInviteToAccept(value.replace('accept-family-profile-invite-', ''));
      else
         setInviteToAccept(null);
   };

   const handleTierComparisonTableOpen = (tier) => {
      setSubscriptionTierToHighlight(tier);
      setTierComparisonDialogOpen(true);
   };

   const renderWizardPage = () => {
      if (wizardPage === WizardPages.findIndex(el => (el.value === 'profile-type')))
         return (
            <Grid container spacing={1}>
               <Grid item xs={12}>
                  <FormControl>
                     <FormLabel id="profile-type-selection-label" sx={{ mt: 2 }}>LifeTomb offers several profile types, taylored to fit your unique scenario. You can always change your mind later by going to your account settings.</FormLabel>
                     <RadioGroup
                        aria-labelledby="profile-type-selection-label"
                        sx={{ mt: 2 }}
                        name="profile-type-selection"
                        value={inviteAction}
                        onChange={(event) => handleProfileTypeRadioGroupChange(event.target.value)}
                     >
                        {profilesImInvitedTo.data.map((invite) => (<FormControlLabel
                           key={invite.id}
                           value={`accept-family-profile-invite-${invite.id}`}
                           control={<Radio />}
                           label={`Accept the invite to join ${invite.firstName} ${invite.lastName}'s family profile.`}
                        />))}

                        <FormControlLabel
                           value="create-free-profile"
                           control={<Radio />}
                           label={<Fragment><Chip label="Free" color="primary" sx={{ mr: 1, cursor: 'pointer', minWidth: 86 }} />{['Start for free with your own basic profile', profilesImInvitedTo.data.length ? ' and reject the invite' : undefined].join('')}. <IconButton color="primary" aria-label="more tier info" size="small" onClick={() => handleTierComparisonTableOpen('free')}>
                           <QuestionMarkIcon fontSize="inherit" />
                         </IconButton></Fragment>}
                        />

                        <FormControlLabel
                           value="create-premium-profile"
                           control={<Radio />}
                           label={<Fragment><Chip label="Premium" color="primary" sx={{ mr: 1, cursor: 'pointer', minWidth: 86 }} />{['Create your own profile and add premium features', profilesImInvitedTo.data.length ? ' and reject the invite' : undefined].join('')}. <IconButton color="primary" aria-label="more tier info" size="small" onClick={() => handleTierComparisonTableOpen('premium')}>
                           <QuestionMarkIcon fontSize="inherit" />
                         </IconButton></Fragment>}
                        />

                        {!profilesImInvitedTo.data.length ? <FormControlLabel
                           value="create-family-profile"
                           control={<Radio />}
                           label={<Fragment><Chip label="Family" color="primary" sx={{ mr: 1, cursor: 'pointer', minWidth: 86 }} />Create a family profile, invite someone to share it with, get premium features and a discount. <IconButton color="primary" aria-label="more tier info" size="small" onClick={() => handleTierComparisonTableOpen('family')}>
                           <QuestionMarkIcon fontSize="inherit" />
                         </IconButton></Fragment>}
                        /> : null}
                     </RadioGroup>
                  </FormControl>
               </Grid>

               <Grid item xs={10} sx={{ display: 'flex' }} />
               <Grid item xs={2} sx={{ display: 'flex' }}>
                  <LoadingButton
                     loading={profilesImInvitedTo.loading}
                     loadingPosition="start"
                     startIcon={<ArrowForwardIcon />}
                     variant="contained"
                     onClick={() => navigateToWizardPage('personal-info')}
                     sx={{ flexGrow: 1 }}
                  >
                     Continue
                  </LoadingButton>
               </Grid>
            </Grid>);

      if (wizardPage === WizardPages.findIndex(el => (el.value === 'personal-info')))
         return (<Box sx={{mt: 4}}>
            <ProfileForm
               ref={profileFormRef}
               genders={[]} // passing in empty list since this question is not asked on sign-up, but can be optionally answered in the profile
               maritalStatuses={maritalStatuses.data}
               addressStatesProvinces={addressStatesProvinces.data}
            />

            <Grid container spacing={1} sx={{ mt: 1 }}>
               <Grid item xs={12}>
                  <FormGroup sx={{display: 'inline-block'}}>
                     <FormControlLabel sx={{ mr: 0.5 }} control={<Checkbox checked={termsAndConditions.accepted} onChange={handleTermsAndConditionsCheck} />} label="Accept" />
                     <Fragment><MUILink onClick={() => setTermsAndConditionsDialogOpen(true)} sx={{cursor: 'pointer'}}>Terms of Use</MUILink>.</Fragment>
                  </FormGroup>
               </Grid>

               <Grid item xs={3} sx={{ display: 'flex' }}>
                  <LoadingButton
                     loading={loading}
                     loadingPosition="start"
                     startIcon={<ArrowBackIcon />}
                     variant="outlined"
                     onClick={() => navigateToWizardPage('profile-type')}
                     sx={{ flexGrow: 1 }}
                  >
                     Back
                  </LoadingButton>
               </Grid>
               <Grid item xs={6} sx={{ display: 'flex' }} />
               <Grid item xs={3} sx={{ display: 'flex' }}>
                  <LoadingButton
                     loading={loading}
                     disabled={!termsAndConditions.accepted}
                     loadingPosition="start"
                     startIcon={renderNextButtonIcon()}
                     variant="contained"
                     onClick={handlePersonInfoContinue}
                     sx={{ flexGrow: 1 }}
                  >
                     {renderNextButtonName()}
                  </LoadingButton>
               </Grid>
            </Grid>
         </Box>);
   };

   return (
      <Fragment>
         <Box
            sx={{
               display: 'flex',
               flexWrap: 'wrap',
               '& > :not(style)': {
                  ml: 'auto',
                  mr: 'auto',
                  p: 3,
               },
            }}
         >
            <Typography variant="h2" component="h2">
               Welcome to LifeTomb! Let's Get Started.
            </Typography>

            {(termsAndConditions.loading || maritalStatuses.loading || addressStatesProvinces.loading || profilesImInvitedTo.loading) ? <Skeleton variant='rectangle' width='60vw' height='20em'><LoaderIcon /></Skeleton> : <Paper sx={{ width: '60vw', borderRadius: '0.9em' }} elevation={3}>
               <Stepper activeStep={wizardPage} alternativeLabel>
                  {WizardPages.map((step) => (
                     <Step key={step.value}>
                        <StepLabel>{step.label}</StepLabel>
                     </Step>
                  ))}
               </Stepper>

               {renderWizardPage()}
            </Paper>}
         </Box>
         {/* subscription tier explanations */}
         <DialogComponent
            open={tierComparisonDialogOpen}
            title="LifeTomb Subscription Tiers"
            dismissButtonLabel="Close"
            handleDismiss={() => setTierComparisonDialogOpen(false)}
         >
            <TierComparisonTable highlightTier={subscriptionTierToHighlight} />
         </DialogComponent>

         {/* terms of use dialog */}
         <DialogComponent
            open={termsAndConditionsDialogOpen}
            title="Terms of Use"
            dismissButtonLabel="Close"
            handleDismiss={() => setTermsAndConditionsDialogOpen(false)}
         >
            <Paper variant="outlined" sx={{height: 400, overflowY: 'scroll', fontSize: '0.8em'}}>
               { <div dangerouslySetInnerHTML={{ __html: xss(termsAndConditions.data.text) }} /> }
            </Paper>
         </DialogComponent>
      </Fragment>
   );
};

export default GetStartedPage;
