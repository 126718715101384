import React, { useState, forwardRef, useRef, useImperativeHandle, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import AddressFormFields from './AddressFormFields';
import Avatar from '@mui/material/Avatar';
import PhotoUploader from './PhotoUploader';
import { Button, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DateField from './DateField';
import { differenceInCalendarYears } from 'date-fns'

const ProfileForm = forwardRef((props, ref) => {
   const addressFormRef = useRef();
   const photoUploaderRef = useRef();

   const initialState = {
      firstName: '',
      preferredName: '',
      middleName: '',
      lastName: '',
      maidenName: '',
      dateOfBirth: null,
      cityOfBirth: '',
      avatarBase64: undefined,
      military: false,
   };
   const [formValues, setFormValues] = useState(initialState);
   const [activeGender, setActiveGender] = useState('select-gender');
   const [activeMaritalStatus, setActiveMaritalStatus] = useState('select-marital-status');

   // validation
   const [formValidation, setFormValidation] = useState({});
   const formIsValid = () => {
      let errors = {};

      if (formValues.firstName.length < 2)
         errors.firstName = 'First Name must be at least two characters.';
      if (formValues.preferredName && formValues.preferredName.length < 2)
         errors.preferredName = 'Preferred Name must be at least two characters if provided.';
      if (formValues.middleName && formValues.middleName.length < 2)
         errors.middleName = 'Middle Name must be at least two characters if provided.';
      if (formValues.lastName.length < 2)
         errors.lastName = 'Last Name must be at least two characters.';
      if (formValues.maidenName && formValues.maidenName.length < 2)
         errors.maidenName = 'Maiden Name must be at least two characters if provided.';
      if (!formValues.dateOfBirth)
         errors.dateOfBirth = 'Date of Birth must be provided.';
      else if(differenceInCalendarYears(new Date(), formValues.dateOfBirth) < 18)
         errors.dateOfBirth = 'You must be 18 or older to use LifeTomb.';
      if (formValues.cityOfBirth.length < 2)
         errors.cityOfBirth = 'City of Birth must be at least two characters.';

      // validate address
      let addressValid = false;
      if (addressFormRef.current)
         addressValid = addressFormRef.current.validate();

      setFormValidation(errors);

      return Object.keys(errors).length === 0 && addressValid;
   };

   // control validation, state from parent component
   useImperativeHandle(ref, () => ({
      validate() {
         return formIsValid();
      },

      setState(state) {
         setFormValues({
            firstName: state.firstName || '',
            preferredName: state.preferredName || '',
            middleName: state.middleName || '',
            lastName: state.lastName || '',
            maidenName: state.maidenName || '',
            dateOfBirth: state.dateOfBirth || null,
            cityOfBirth: state.cityOfBirth || '',
            avatarBase64: state.avatarBase64 || undefined,
            military: state.military || false,
         });

         if (addressFormRef.current)
            addressFormRef.current.setState(state.address);

         setActiveGender((state && state.gender && state.gender.id) || 'select-gender');
         setActiveMaritalStatus((state && state.maritalStatus && state.maritalStatus.id) || 'select-marital-status');
      },

      getState() {
         return cleanData(formValues);
      }
   }));

   const handleFirstNameChange = (event) => {
      setFormValues({ ...formValues, firstName: event.target.value });
   };

   const handlePreferredNameChange = (event) => {
      setFormValues({ ...formValues, preferredName: event.target.value });
   };

   const handleMiddleNameChange = (event) => {
      setFormValues({ ...formValues, middleName: event.target.value });
   };

   const handleLastNameChange = (event) => {
      setFormValues({ ...formValues, lastName: event.target.value });
   };

   const handleMaidenNameChange = (event) => {
      setFormValues({ ...formValues, maidenName: event.target.value });
   };

   const handleCityOfBirthChange = (event) => {
      setFormValues({ ...formValues, cityOfBirth: event.target.value });
   };

   const handleGenderChange = (event) => {
      setActiveGender(event.target.value);
   };

   const handleMaritalStatusChange = (event) => {
      setActiveMaritalStatus(event.target.value);
   };

   const cleanData = (formValues) => {
      let avatarBase64;
      if(editingAvatar && photoUploaderRef.current) {
         const newAvatar = photoUploaderRef.current.getPhoto();
         avatarBase64 = newAvatar ? newAvatar.base64PhotoScaled : formValues.avatarBase64;
      } else {
         avatarBase64 = formValues.avatarBase64;
      }

      return {
         ...formValues,
         preferredName: formValues.preferredName ? formValues.preferredName : null,
         middleName: formValues.middleName ? formValues.middleName : null,
         maidenName: formValues.maidenName ? formValues.maidenName : null,
         avatarBase64: avatarBase64 ? avatarBase64 : null,
         gender: props.includeGenderField ? props.genders.find(element => element.id === activeGender) : null,
         maritalStatus: props.maritalStatuses.find(element => element.id === activeMaritalStatus),
         address: addressFormRef.current ? addressFormRef.current.getState() : undefined,
         military: formValues.military ? formValues.military : false,
      };
   };

   const [editingAvatar, setEditingAvatar] = useState(false);

   return (
      <Box component="form">
         <Grid container spacing={1}>
            {!editingAvatar ? <Fragment>
               <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                     <Avatar
                        alt={`${formValues.lastName} ${formValues.lastName}`}
                        src={formValues.avatarBase64}
                        sx={{ width: 180, height: 180 }}
                     />
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                     <Button onClick={() => setEditingAvatar(true)}>Edit Avatar</Button>
                  </Box>
               </Grid>
            </Fragment> : null }

            {editingAvatar ? <Fragment>
               <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                     <PhotoUploader
                        ref={photoUploaderRef}
                        width={420}
                        height={420}
                     />
                  </Box>
               </Grid>
               <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                     <Button onClick={() => setEditingAvatar(false)}>Cancel Edit</Button>
                  </Box>
               </Grid>
            </Fragment> : null}

            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="first-name-input"
                     label="Legal First Name*"
                     type="text"
                     value={formValues.firstName}
                     onChange={event => handleFirstNameChange(event)}
                     error={formValidation.firstName ? true : false}
                     helperText={formValidation.firstName}
                     fullWidth
                  />
               </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="preferred-name-input"
                     label="Preferred Name"
                     type="text"
                     value={formValues.preferredName}
                     onChange={event => handlePreferredNameChange(event)}
                     error={formValidation.preferredName ? true : false}
                     helperText={formValidation.preferredName}
                     fullWidth
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="last-name-input"
                     label="Legal Last Name*"
                     type="text"
                     value={formValues.lastName}
                     onChange={event => handleLastNameChange(event)}
                     error={formValidation.lastName ? true : false}
                     helperText={formValidation.lastName}
                     fullWidth
                  />
               </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="maiden-name-input"
                     label="Maiden Name"
                     type="text"
                     value={formValues.maidenName}
                     onChange={event => handleMaidenNameChange(event)}
                     error={formValidation.maidenName ? true : false}
                     helperText={formValidation.maidenName}
                     fullWidth
                  />
               </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="middle-name-input"
                     label="Middle Name"
                     type="text"
                     value={formValues.middleName}
                     onChange={event => handleMiddleNameChange(event)}
                     error={formValidation.middleName ? true : false}
                     helperText={formValidation.middleName}
                     fullWidth
                  />
               </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
               <FormControl fullWidth>
                  <TextField
                     margin="dense"
                     id="city-of-birth-input"
                     label="City of Birth*"
                     type="text"
                     value={formValues.cityOfBirth}
                     onChange={event => handleCityOfBirthChange(event)}
                     error={formValidation.cityOfBirth ? true : false}
                     helperText={formValidation.cityOfBirth}
                     fullWidth
                  />
               </FormControl>
            </Grid>

            <Grid item md={12}>
               <FormControl fullWidth>
                  <DateField
                     label="Date of Birth*"
                     value={formValues.dateOfBirth}
                     onChange={(newValue) => {
                        setFormValues({ ...formValues, dateOfBirth: newValue });
                     }}
                     error={formValidation.dateOfBirth}
                  />
               </FormControl>
            </Grid>
            {props.includeGenderField ? <Grid item md={12}>
               <FormControl fullWidth>
                  <InputLabel id="gender-select-label">Gender</InputLabel>
                  <Select
                     labelId="gender-select-label"
                     id="gender-select"
                     label="Gender"
                     value={activeGender}
                     onChange={event => handleGenderChange(event)}
                     // error={formValidation.gender ? true : false}
                     sx={{ marginTop: '0.5em' }}
                  >
                     <MenuItem value="select-gender">--- Select Gender ---</MenuItem>
                     {props.genders.map(gender => (<MenuItem key={gender.id} value={gender.id}>{gender.name}</MenuItem>))}
                  </Select>
                  {formValidation.gender ? <FormHelperText error>{formValidation.gender}</FormHelperText> : null}
               </FormControl>
            </Grid> : null}
            <Grid item md={12}>
               <FormControl fullWidth>
                  <InputLabel id="marital-status-select-label">Marital Status</InputLabel>
                  <Select
                     labelId="marital-status-select-label"
                     id="marital-status-select"
                     label="Marital Status"
                     value={activeMaritalStatus}
                     onChange={event => handleMaritalStatusChange(event)}
                     // error={formValidation.maritalStatus ? true : false}
                     sx={{ marginTop: '0.5em' }}
                  >
                     <MenuItem value="select-marital-status">--- Select Marital Status ---</MenuItem>
                     {props.maritalStatuses.map(maritalStatus => (<MenuItem key={maritalStatus.id} value={maritalStatus.id}>{maritalStatus.name}</MenuItem>))}
                  </Select>
                  {formValidation.maritalStatus ? <FormHelperText error>{formValidation.maritalStatus}</FormHelperText> : null}
               </FormControl>
            </Grid>
            <Grid item md={12}>
               <FormControl fullWidth>
                  <FormControlLabel sx={{ mr: 0.5 }} control={<Checkbox checked={Boolean(formValues.military)} onChange={(event) => setFormValues({ ...formValues, military: event.target.checked})}/>} label="Active Duty or Retired Military?" />
               </FormControl>
            </Grid>

            <AddressFormFields
               ref={addressFormRef}
               label="Current Address"
               addressStatesProvinces={props.addressStatesProvinces}
               isRequired={true}
            />
         </Grid>
      </Box>
   )
});

ProfileForm.propTypes = {
   genders: PropTypes.array.isRequired,
   maritalStatuses: PropTypes.array.isRequired,
   addressStatesProvinces: PropTypes.array.isRequired,
   includeGenderField: PropTypes.bool,
};

export default ProfileForm;
